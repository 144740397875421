import type { FunctionComponent } from "preact";
import { h, render } from "preact";
import { useContext, useEffect, useRef, useState } from "preact/hooks";

import { PageContext } from "@/lib/context";
import type { EngageAppContextType, EngageAppOptions, Widget } from "@/types";
import { CustomWidget, Prompt, Video } from "@/widgets";
import EngageTray from "@/widgets/tray";
import { NewReleaseModal } from "./new-feature";
import Slide from "./slide";

type DisplayManagerProps = {
  widgets: Widget[];
  isLoadingWidgets: boolean;
};

const contextKey = "engagejs";
const initialDisplayDelay = 2000;

const getWidgetDisplayFlag = (widget: Widget): boolean => {
  let contextString = localStorage.getItem(contextKey);
  const key = widget.id.toString();
  let widgetFlags = Object();
  if (contextString) {
    const context = JSON.parse(contextString);
    widgetFlags = context?.widgetFlags ?? {};
    if (widgetFlags[key] === undefined) {
      return true;
    }
    return widgetFlags[key];
  }
  return true;
};

const setWidgetDisplayFlag = (widget: Widget, flag: boolean) => {
  let contextString = localStorage.getItem(contextKey);
  let widgetFlags = Object();
  let context = {};
  if (contextString) {
    const context = JSON.parse(contextString);
    widgetFlags = context?.widgetFlags ?? {};
  }
  const key = widget.id.toString();
  widgetFlags[key] = flag;
  localStorage.setItem(
    contextKey,
    JSON.stringify({
      ...context,
      widgetFlags,
    }),
  );
};

const getDisplayWidget = (widgets: Widget[]): Widget | null => {
  // localStorage.removeItem(contextKey);

  for (let i = 0; i < widgets.length; i++) {
    if (widgets[i].show_on_load) {
      const flag = getWidgetDisplayFlag(widgets[i]);

      if (flag) {
        return widgets[i];
      }
    }
  }
  return null;
};

const DisplayManager: FunctionComponent<DisplayManagerProps> = ({
  widgets,
  isLoadingWidgets,
}) => {
  const [displayWidget, _setDisplayWidget] = useState<Widget | null>(null);
  const [show, setShow] = useState<boolean>(false);
  const closed = useRef<boolean>(false);
  const displayWidgetRef = useRef<Widget | null>(null);

  const setDisplayWidget = (data: Widget) => {
    displayWidgetRef.current = data;
    _setDisplayWidget(data);
  };

  const handleCloseWidget = (forever: boolean) => {
    setShow(false);
    if (displayWidget && forever) {
      setWidgetDisplayFlag(displayWidget, false);
    }
    closed.current = true;
  };

  const handlePageScroll = () => {
    let scrollHeight = Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight,
    );
    const percentScrolled = window.scrollY / scrollHeight;

    if (
      displayWidgetRef.current?.display_config.scroll_percent !== undefined &&
      percentScrolled >=
        displayWidgetRef.current?.display_config.scroll_percent &&
      !show &&
      !closed.current
    ) {
      setShow(true);
    }
  };

  useEffect(() => {
    if (!isLoadingWidgets && widgets.length) {
      const widget = getDisplayWidget(widgets);
      if (widget) {
        setDisplayWidget(widget);
      }

      if (widget?.display_config.trigger === "time") {
        let delay = initialDisplayDelay;
        if (widget?.display_config?.delay) {
          delay = widget.display_config.delay * 1000; // delay is in seconds
        }
        setTimeout(() => {
          if (widget) {
            setShow(true);
          }
        }, delay);
      } else if (widget?.display_config.trigger === "scroll") {
        window.addEventListener("scroll", handlePageScroll);
      } else {
        console.warn(
          `Invalid display trigger: ${widget?.display_config.trigger}`,
        );
      }
    }
  }, [isLoadingWidgets]);

  if (isLoadingWidgets) {
    return <div></div>;
  } else {
    if (displayWidget) {
      switch (displayWidget.widget_type) {
        case "video":
          return (
            <Video
              src={displayWidget.config.src}
              text={displayWidget.config.text}
              show={show}
              mode="display"
              onClose={handleCloseWidget}
            ></Video>
          );
        case "prompt":
          return (
            <Prompt
              href={displayWidget.config.href}
              title={displayWidget.config.title}
              description={displayWidget.config.description}
              action={displayWidget.config.action}
              template={
                displayWidget.content_type === "custom" ||
                displayWidget.content_type === "dynamic"
                  ? displayWidget.rendered_template
                  : ""
              }
              show={show}
              mode="display"
              onClose={handleCloseWidget}
            ></Prompt>
          );

        case "slide":
          return (
            <Slide
              mode="display"
              href={displayWidget.config.href}
              title={displayWidget.config.title}
              description={displayWidget.config.description}
              action={displayWidget.config.action}
              show={show}
              template={
                displayWidget.content_type === "custom" ||
                displayWidget.content_type === "dynamic"
                  ? displayWidget.rendered_template
                  : ""
              }
              onClose={handleCloseWidget}
              actionClass={
                displayWidget.target === "marketing" ? "bg-orange-500" : ""
              }
            ></Slide>
          );
        case "custom":
          return (
            <CustomWidget
              template={
                displayWidget.content_type === "custom" ||
                displayWidget.content_type === "dynamic"
                  ? displayWidget.rendered_template
                  : ""
              }
              show={show}
              mode="display"
              onClose={handleCloseWidget}
            ></CustomWidget>
          );

        default:
          return <div></div>;
      }
    } else {
      return <div></div>;
    }
  }
};

type WidgetAPIResp = {
  payload: Widget[];
  num_pages: number;
};

const EngageApp: FunctionComponent = () => {
  const [isLoadingWidgets, setLoadingWidgets] = useState<boolean>(false);
  const [releaseModalClosed, setReleaseModalClosed] = useState(false);
  const [widgets, setWidgets] = useState<Widget[]>([]);
  const context = useContext(PageContext);
  const enableTray = context.target !== "marketing";
  const enableNewReleaseModal = context.target !== "marketing";

  const loadWidgets = async () => {
    const path = window.location.pathname;
    try {
      setLoadingWidgets(true);
      let params: any = {
        page_type: context.pageType,
        target: context.target,
        path: path,
      };
      if (context.widgetIds?.length) {
        params["ids"] = context.widgetIds.join(",");
      }
      const resp = await fetch(
        `${context.widgetApi}?` + new URLSearchParams(params),
      );
      if (resp.ok) {
        const data = (await resp.json()) as WidgetAPIResp;
        if (data.payload) {
          const enabledWidgets = data.payload.filter((w) => w.enabled);
          setWidgets(enabledWidgets);
        }
      } else {
        console.error(`EngageJS: Failed to load widgets: ${resp.statusText}`);
      }
    } catch (e) {
    } finally {
      setLoadingWidgets(false);
    }
  };
  useEffect(() => {
    loadWidgets();
  }, []);

  const newReleaseModalStateKey = "engagejs:new-release-closed";

  const handleNewReleaseModalClose = () => {
    sessionStorage.setItem(newReleaseModalStateKey, "1");
    setReleaseModalClosed(true);
  };

  const showReleaseUntil = Date.parse("2023-02-24");
  const releaseModalExpired = Date.now() > showReleaseUntil;
  const didUserClose = sessionStorage.getItem(newReleaseModalStateKey) === "1";
  const showReleaseModal =
    enableNewReleaseModal && !didUserClose && !releaseModalExpired;

  return (
    <div>
      {enableTray && (
        <EngageTray
          widgets={widgets}
          isLoadingWidgets={isLoadingWidgets}
          helpCenter={context.helpCenter}
          user={context.user}
        ></EngageTray>
      )}
      <DisplayManager
        widgets={widgets}
        isLoadingWidgets={isLoadingWidgets}
      ></DisplayManager>
      {showReleaseModal && !releaseModalClosed && (
        <NewReleaseModal onClose={handleNewReleaseModalClose}></NewReleaseModal>
      )}
    </div>
  );
};

export const renderApp = (
  containerNode: HTMLElement,
  options: EngageAppOptions,
) => {
  const { pageType = "", target } = options;

  let context: EngageAppContextType = {
    pageType: pageType,
    target,
    widgetApi: options.widgetApi,
    widgetIds: options.widgetIds ?? [],
    trayConfig: options.trayConfig,
    helpCenter: options.helpCenter,
    user: options.user,
  };

  render(
    <PageContext.Provider value={context}>
      <EngageApp />
    </PageContext.Provider>,
    containerNode,
  );
};
