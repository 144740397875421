import { createContext } from "preact";
import { EngageAppContextType } from "@/types";

export const PageContext = createContext<EngageAppContextType>({
  pageType: "",
  target: "web",
  widgetApi: "",
  widgetIds: [] as number[],
  trayConfig: {
    placement: "right",
  },
});
