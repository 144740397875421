import { FunctionalComponent, h } from "preact";

type Props = {
  onClose: () => void;
};
export const NewReleaseModal: FunctionalComponent<Props> = ({ onClose }) => {
  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-30 transition-opacity"></div>

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full bg-opacity-10 items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 md:max-w-2xl">
            <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
              <button
                type="button"
                onClick={onClose}
                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                <span className="sr-only">Close</span>

                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="">
              <h2 className="text-center font-medium text-gray-500">
                What's new?
              </h2>
              <div className="mt-4">
                <p className=" font-bold text-gray-700">
                  Strategies and tactics
                </p>
              </div>
              <div className="mt-8 h-72 overflow-y-auto p-1">
                <ul className="space-y-4">
                  <li className="cursor-pointer rounded-md px-4 py-2 shadow hover:shadow-md">
                    <h3 className="text-sm font-medium">Strategies</h3>
                    <p className="mt-2 text-sm text-gray-500">
                      Successful stores employ proven, high-impact growth
                      strategies like pricing power, reducing customer
                      acquisition cost, avoiding stagnant inventory, and
                      increasing customer lifetime value. Now, you can view
                      Konigle features organized by strategies on the left side
                      in the dashboard.
                    </p>
                  </li>
                  <li className="cursor-pointer rounded-md px-4 py-2 shadow hover:shadow-md">
                    <h3 className="text-sm font-medium">Tactics</h3>
                    <p className="mt-2 text-sm text-gray-500">
                      We have identified profitable tactics to implement
                      high-impact growth strategies, and made them easy to
                      implement via our Seller Tools. Clicking on Strategies
                      reveals the Tactics you can implement.
                    </p>
                  </li>
                  <li className="cursor-pointer rounded-md px-4 py-2 shadow hover:shadow-md">
                    <h3 className="text-sm font-medium">
                      How to use the old Konigle User Interface
                    </h3>
                    <p className="mt-2 text-sm text-gray-500">
                      Feeling overwhelmed by the new user interface? You can
                      switch back to the old one by clicking Switch to old home
                      in the left side of the dashboard.
                    </p>
                  </li>
                </ul>
              </div>
              <div class="mt-4 flex justify-end">
                <a
                  href="/admin/home"
                  onClick={onClose}
                  className="bg-primary text-white px-3 py-2 rounded-md text-sm"
                >
                  Explore now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
