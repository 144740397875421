import type { EngageJSOptions } from "@/types";
import EngageJS from "@/lib/engage";

type EngageJSModule = {
  (options: EngageJSOptions): EngageJS;
  version: string;
};

const engagejs: EngageJSModule = (options) => new EngageJS(options);
engagejs.version = "1.0.0";

export default engagejs;
