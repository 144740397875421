import { FunctionComponent, h } from "preact";
import { useState, useEffect } from "preact/hooks";

import SkeletonLoader from "@/components/Loader";
import type {
  TrayConfig,
  TrayStaticLink,
  Widget,
  HelpCenterConfig,
  User,
} from "@/types";
import { CustomWidget, Prompt, Link, Video } from "@/widgets";
import { Tab, Tabs } from "@/components/Tabs";
import classNames from "classnames";
import { usePrevious } from "@/lib/hooks";

type WidgetsTabProps = {
  widgets: Widget[];
  isLoadingWidgets: boolean;
};

const WidgetsTab: FunctionComponent<WidgetsTabProps> = ({
  widgets,
  isLoadingWidgets,
}) => {
  if (isLoadingWidgets) {
    return <SkeletonLoader></SkeletonLoader>;
  }
  const listItemClass = "py-4 rounded-md shadow-sm px-2 border border-gray-100";
  if (widgets.length) {
    return (
      <ul className="px-2 space-y-2 mb-8">
        {widgets.map((widget) => {
          switch (widget.widget_type) {
            case "link":
              return (
                <li className={listItemClass}>
                  <Link
                    href={widget.config.href}
                    text={widget.config.text}
                    template={
                      widget.content_type === "custom" ||
                      widget.content_type === "dynamic"
                        ? widget.rendered_template
                        : ""
                    }
                  ></Link>
                </li>
              );
            case "video":
              return (
                <li className={listItemClass}>
                  <Video
                    src={widget.config.src}
                    text={widget.config.text}
                  ></Video>
                </li>
              );
            case "prompt":
              return (
                <li className={listItemClass}>
                  <Prompt
                    href={widget.config.href}
                    title={widget.config.title}
                    description={widget.config.description}
                    action={widget.config.action}
                    template={
                      widget.content_type === "custom" ||
                      widget.content_type === "dynamic"
                        ? widget.rendered_template
                        : ""
                    }
                  ></Prompt>
                </li>
              );
            case "custom":
              return (
                <li className={listItemClass}>
                  <CustomWidget
                    template={
                      widget.content_type === "custom" ||
                      widget.content_type === "dynamic"
                        ? widget.rendered_template
                        : ""
                    }
                  ></CustomWidget>
                </li>
              );
            default:
              return <div></div>;
          }
        })}
      </ul>
    );
  } else {
    return (
      <div className="px-2 flex items-center justify-center">
        <p className="text-sm text-gray-700">No tips available!</p>
      </div>
    );
  }
};

const StaticButtonLink: FunctionComponent<{
  text: string;
  onPress?: () => void;
}> = (props) => {
  return (
    <button
      className="flex w-full px-2 py-2 text-left text-gray-800 text-sm justify-between items-center rounded-md hover:bg-gray-100 hover:text-blue-600"
      onClick={() => {
        if (props.onPress) {
          props.onPress();
        }
      }}
    >
      <span>{props.text}</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        class="w-5 h-5 text-blue-600"
      >
        <path
          fill-rule="evenodd"
          strokeWidth={2}
          d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
          clip-rule="evenodd"
        />
      </svg>
    </button>
  );
};

const StaticAnchorLink: FunctionComponent<{
  text: string;
  href: string;
}> = (props) => {
  return (
    <a
      href={props.href}
      className="flex w-full px-2 py-2 text-left text-gray-800 text-sm justify-between items-center rounded-md hover:bg-gray-100 hover:text-blue-600"
      target="_blank"
    >
      <span>{props.text}</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        class="w-5 h-5 text-blue-600"
      >
        <path
          fill-rule="evenodd"
          strokeWidth={2}
          d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
          clip-rule="evenodd"
        />
      </svg>
    </a>
  );
};

const TrayStaticLinks: FunctionComponent<{ links: TrayStaticLink[] }> = (
  props
) => {
  return (
    <div className="">
      {props.links.map((link) => {
        switch (link.type) {
          case "button":
            return (
              <StaticButtonLink
                text={link.text}
                onPress={link.onPress}
              ></StaticButtonLink>
            );
          case "a":
            return (
              <StaticAnchorLink
                text={link.text}
                href={link.href ?? "#"}
              ></StaticAnchorLink>
            );
        }
      })}
    </div>
  );
};

type HomeTabProps = {
  onExternalWidgetOpen?: () => void;
  onExternalWidgetHide?: () => void;
  config?: HelpCenterConfig;
  user?: User;
};

const HomeTab: FunctionComponent<HomeTabProps> = ({
  onExternalWidgetHide = () => {},
  onExternalWidgetOpen = () => {},
  config = {},
  user,
}) => {
  const { enableChatLauncher = true, links = [] } = config;

  const chatButton: TrayStaticLink = {
    type: "button",
    text: "Chat with us",
    onPress: () => {
      console.log("open chat");
      openIntercom();
    },
  };

  const openIntercom = () => {
    if (window.hasOwnProperty("Intercom")) {
      window.Intercom("showMessages");
      onExternalWidgetOpen();
    } else {
      console.log("Intercom not loaded!");
    }
  };

  useEffect(() => {
    if (window.hasOwnProperty("Intercom")) {
      window.Intercom("onHide", () => {
        onExternalWidgetHide();
      });
    } else {
      console.log(
        "Intercom not loaded. The engage tray may not re-open after closing the chat"
      );
    }
  }, []);

  return (
    <div className="px-4  mb-6">
      {user?.name && (
        <h3 className="text-gray-600">{`Hello ${user.name}  👋`}</h3>
      )}
      {!user?.name && (
        <h3 class="text-lg font-bold text-gray-700 lg:text-xl">Help</h3>
      )}

      {links.length && (
        <div className="mt-6 rounded-lg bg-white shadow px-2 py-2">
          <TrayStaticLinks
            links={enableChatLauncher ? [chatButton, ...links] : links}
          ></TrayStaticLinks>
        </div>
      )}
    </div>
  );
};

export type EngageTrayProps = {
  trayConfig?: TrayConfig;
  helpCenter?: HelpCenterConfig;
  user?: User;
} & WidgetsTabProps;

const EngageTray: FunctionComponent<EngageTrayProps> = (props) => {
  const [isOpen, setOpen] = useState(false);

  const {
    trayConfig = {
      placement: "right",
    },
  } = props;

  const handleExternalWidgetOpen = () => {
    setOpen(false);
  };

  const handleExternalWidgetClose = () => {
    setOpen(true);
  };

  return (
    <div
      className={classNames([
        "fixed bottom-4 z-10",
        trayConfig.placement === "left" ? "left-4" : "right-4",
      ])}
    >
      <div className="group block flex-shrink-0">
        <div className="flex items-center">
          <div className="">
            <div
              className="relative"
              style={{ visibility: isOpen ? "visible" : "hidden" }}
            >
              <div
                className={classNames(
                  "absolute bottom-0 bg-white mt-2 -mr-1 w-[320px] origin-top-right  rounded-md  shadow-lg",
                  trayConfig.placement === "left" ? "left-0" : "right-0"
                )}
                style={{ zIndex: 1000000 }}
              >
                <div className="shadow-xs relative rounded-md bg-white py-1">
                  <div className="flex justify-end px-2">
                    <button
                      onClick={() => setOpen(false)}
                      className="cursor-pointer rounded-md p-1 hover:bg-gray-300"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6 text-gray-500"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>

                  <div className="mt-2 h-80 flex flex-col  justify-end">
                    <Tabs>
                      <Tab
                        label="Help"
                        icon={() => (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            class="w-6 h-6"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        )}
                      >
                        <HomeTab
                          onExternalWidgetHide={handleExternalWidgetClose}
                          onExternalWidgetOpen={handleExternalWidgetOpen}
                          config={props.helpCenter}
                          user={props.user}
                        ></HomeTab>
                      </Tab>
                      <Tab
                        label="Tips"
                        icon={() => (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            class="w-6 h-6"
                          >
                            <path d="M12 .75a8.25 8.25 0 00-4.135 15.39c.686.398 1.115 1.008 1.134 1.623a.75.75 0 00.577.706c.352.083.71.148 1.074.195.323.041.6-.218.6-.544v-4.661a6.714 6.714 0 01-.937-.171.75.75 0 11.374-1.453 5.261 5.261 0 002.626 0 .75.75 0 11.374 1.452 6.712 6.712 0 01-.937.172v4.66c0 .327.277.586.6.545.364-.047.722-.112 1.074-.195a.75.75 0 00.577-.706c.02-.615.448-1.225 1.134-1.623A8.25 8.25 0 0012 .75z" />
                            <path
                              fill-rule="evenodd"
                              d="M9.013 19.9a.75.75 0 01.877-.597 11.319 11.319 0 004.22 0 .75.75 0 11.28 1.473 12.819 12.819 0 01-4.78 0 .75.75 0 01-.597-.876zM9.754 22.344a.75.75 0 01.824-.668 13.682 13.682 0 002.844 0 .75.75 0 11.156 1.492 15.156 15.156 0 01-3.156 0 .75.75 0 01-.668-.824z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        )}
                      >
                        <WidgetsTab
                          widgets={props.widgets}
                          isLoadingWidgets={props.isLoadingWidgets}
                        ></WidgetsTab>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-2">
              <button
                onClick={() => setOpen(!isOpen)}
                className="flex border border-gray-200 bg-accent text-accent-content items-center justify-center p-1 rounded-full text-sm shadow"
              >
                {!isOpen && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-8 h-8"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
                    />
                  </svg>
                )}
                {isOpen && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-8 h-8"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EngageTray;
