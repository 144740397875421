import { h, FunctionComponent } from "preact";

export type Props = {
  repeat?: number;
};

const SkeletonLoader: FunctionComponent<Props> = ({ repeat = 1 }) => {
  return (
    <div class="mx-auto w-full p-4">
      {Array.from({ length: repeat }, (_, i) => {
        return (
          <div class="flex animate-pulse space-x-4" key={i}>
            <div class="h-10 w-10 rounded-full bg-slate-200"></div>
            <div class="flex-1 space-y-6 py-1">
              <div class="h-2 rounded bg-slate-200"></div>
              <div class="space-y-3">
                <div class="grid grid-cols-3 gap-4">
                  <div class="col-span-2 h-2 rounded bg-slate-200"></div>
                  <div class="col-span-1 h-2 rounded bg-slate-200"></div>
                </div>
                <div class="h-2 rounded bg-slate-200"></div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SkeletonLoader;
