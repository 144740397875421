import { h, ComponentChild } from "preact";

const parseHtml = (html: string): NodeListOf<ChildNode> => {
  const hRoot = document.createElement("div");
  hRoot.innerHTML = html;

  return hRoot.childNodes;
};

const createType = (tag: string): string => {
  return tag.toLowerCase();
};

const createProperties = (attributes: NamedNodeMap): Record<string, any> => {
  const properties: Record<string, any> = {};
  for (let i = 0; i < attributes.length; ++i) {
    const attribute = attributes[i];
    properties[attribute.name] = attribute.value;
  }
  return properties;
};

const convertElement = (hElement: Element): ComponentChild => {
  const type = createType(hElement.tagName);
  const properties = createProperties(hElement.attributes);
  const children = convertNodes(hElement.childNodes);
  return h(type, properties, children);
};

const convertText = (hText: Text): string => {
  return hText.data;
};

const convertNode = (hNode: ChildNode): ComponentChild | null => {
  if (hNode.nodeType === 1) {
    // Node.ELEMENT_NODE
    return convertElement(hNode as Element);
  }
  if (hNode.nodeType === 3) {
    // Node.TEXT_NODE
    return convertText(hNode as Text);
  }
  return null;
};

const convertNodes = (
  hNodes: NodeListOf<ChildNode>
): Array<ComponentChild> | null => {
  if (hNodes.length > 0) {
    const nodes: Array<ComponentChild> = [];
    for (let i = 0; i < hNodes.length; ++i) {
      const hNode = convertNode(hNodes[i]);
      if (hNode == null) {
        continue;
      }
      nodes.push(hNode);
    }
    return nodes;
  }
  return null; // we want to get self-closed elements in rendering to string on the server when elements are ampty (it means: `render` function returns `<br></br>` instread of `<br />` when empty array is returned)
};

/**
 * Converts HTML to preact nodes.
 *
 * @param html input html
 * @returns preact element created from input HTML or null
 */
export const convertHtml = (
  html?: string | null
): Array<ComponentChild> | null => {
  if (html) {
    const hChildren = parseHtml(html);
    return convertNodes(hChildren);
  }
  return null;
};
