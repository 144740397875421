import { FunctionComponent, h } from "preact";
import classNames from "classnames";

export type Props = {
  show: boolean;
  title?: string;
  onClose: (forever: boolean) => void;
};

const SlideOver: FunctionComponent<Props> = (props) => {
  return (
    <div className="fixed bottom-0 flex justify-center w-full">
      <div
        style={{
          display: props.show ? "block" : "none",
        }}
        className="mb-2 z-50 relative transform transition-all ease-in-out duration-700 overflow-hidden rounded-lg bg-gray-50 px-4 pt-5 pb-4 text-left shadow-xl sm:w-full sm:max-w-lg sm:p-6"
      >
        <div className="absolute top-0 right-0  pt-4 pr-4 ">
          <div className="flex items-center ">
            <button
              type="button"
              onClick={() => props.onClose(true)}
              className="text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
            >
              <span className="underline text-xs">Don't show again</span>
            </button>
            <button
              type="button"
              onClick={() => props.onClose(false)}
              className="rounded-md ml-2 bg-white hover:bg-gray-200 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
            >
              <span className="sr-only">Close</span>

              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="sm:flex sm:items-start">
          <div className="mt-3  flex-1">
            <h3 className=" text-center sm:text-left text-lg font-medium leading-6 text-gray-900">
              {props.title}
            </h3>
            <div
              className={classNames(["mt-2 w-full", props.title ? "" : "mt-6"])}
            >
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlideOver;
