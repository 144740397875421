import { FunctionComponent, h } from "preact";
import { useState } from "preact/hooks";

import type { WidgetMode } from "@/types";
import Modal from "@/components/Modal";
import { convertHtml } from "@/lib/parser";

export type Props = {
  template: string;
  mode?: WidgetMode;
  onClose?: (forever: boolean) => void;
  show?: boolean;
};

const CustomWidget: FunctionComponent<Props> = ({
  mode = "tray",
  show = false,
  onClose = null,
  template = "",
}) => {
  const [showModal, setShowModal] = useState(show);

  if (template !== "") {
    const vnode = convertHtml(template);

    switch (mode) {
      case "tray":
        return <div>{vnode}</div>;
      case "display":
        if (show) {
          return (
            <Modal
              show={showModal}
              onClose={(forever) => {
                setShowModal(false);
                if (onClose) {
                  onClose(forever);
                }
              }}
              title={""}
            >
              {vnode}
            </Modal>
          );
        } else {
          return null;
        }
    }
  } else {
    // custom widgets must specify template
    return null;
  }
};

export default CustomWidget;
