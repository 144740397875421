import { FunctionComponent, h } from "preact";
import { convertHtml } from "@/lib/parser";

export type Props = {
  href: string;
  text: string;
  template?: string;
};

const Link: FunctionComponent<Props> = ({ href, text, template = "" }) => {
  // if custom template is specified, that takes the precedence
  if (template !== "") {
    return <div>{convertHtml(template)}</div>;
  } else {
    return (
      <div className="inline-flex items-center">
        <a
          href={href}
          className="text-gray-700 hover:text-brand-blue"
          target="_blank"
        >
          {text}
        </a>
        <span className="ml-1 text-brand-blue">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-4 w-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
            />
          </svg>
        </span>
      </div>
    );
  }
};

export default Link;
