import { renderApp } from "@/widgets/engage";
import type { Widget, EngageJSOptions, TargetType } from "@/types";

const defaultWidgetsApi = "/admin/engage/widgets";

class EngageJS {
  public pageType: string | undefined = "";
  public target: TargetType;
  _appContainer: HTMLElement | null;
  _widgets: Widget[] = [];
  _isLoadingWidgets: boolean = false;
  _internalContainer: boolean;
  _restOptions: Partial<EngageJSOptions>;
  public widgetApi: string;

  public constructor({
    pageType,
    target = "web",
    containerId,
    widgetApi = defaultWidgetsApi,
    ...rest
  }: EngageJSOptions) {
    this.pageType = pageType;
    this.target = target;
    this.widgetApi = widgetApi;
    this._restOptions = rest;
    this._internalContainer = true;
    if (containerId) {
      this._appContainer = document.getElementById(containerId);
      this._internalContainer = false;
    } else {
      this._appContainer = document.createElement("div");
      this._appContainer.id = "enagejs-app";
      this._appContainer.className = "";
    }
  }

  mountApp() {
    if (this._internalContainer && this._appContainer) {
      document.body.append(this._appContainer);
    }
    if (this._appContainer) {
      renderApp(this._appContainer, {
        pageType: this.pageType || "",
        target: this.target,
        widgetApi: this.widgetApi,
        ...this._restOptions,
      });
    }
  }
  async loadWidgets() {}

  public engage() {
    console.log("Engage JS starting...");
    console.log("mounting...");
    this.mountApp();
  }
}

export default EngageJS;
