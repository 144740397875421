import classNames from "classnames";
import { FunctionComponent, h, VNode } from "preact";
import { useState } from "preact/hooks";

type TabProps = {
  label: string;
  icon?: () => VNode;
};

type TabButtonProps = TabProps & {
  onPress: () => void;
  selected: boolean;
};

const TabButton: FunctionComponent<TabButtonProps> = (props) => {
  return (
    <li className="flex-1 flex flex-col items-center">
      <button
        onClick={props.onPress}
        className={classNames(
          "p-2 rounded-md hover:bg-gray-100 inline-flex flex-col text-sm items-center",
          props.selected ? "text-blue-600" : "text-gray-500"
        )}
      >
        {/* {props.icon && props.icon()} */}
        {props.label}
      </button>
    </li>
  );
};

export const Tab: FunctionComponent<TabProps> = (props) => {
  return <div>{props.children}</div>;
};

type TabsProps = {
  children: VNode<TabProps>[];
};

export const Tabs: FunctionComponent<TabsProps> = ({ children }) => {
  const [activeTab, setActiveTab] = useState<string>(children[0].props.label);

  return (
    <div className="flex flex-col justify-between overflow-y-auto flex-1">
      <div className="flex-1 overflow-y-auto">
        {children &&
          children.map((child) => {
            if (child.props.label !== activeTab) {
              return undefined;
            }
            return child.props.children;
          })}
      </div>
      <div className="shadow border-t py-2 overflow-hidden">
        <ul className="flex justify-between">
          {children &&
            children.map((child) => {
              return (
                <TabButton
                  {...child.props}
                  selected={child.props.label === activeTab}
                  onPress={() => {
                    setActiveTab(child.props.label);
                  }}
                ></TabButton>
              );
            })}
        </ul>
      </div>
    </div>
  );
};
