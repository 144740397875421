import { FunctionComponent, h } from "preact";
import { useEffect, useState } from "preact/hooks";
import type { WidgetMode } from "@/types";
import SlideOver from "@/components/SlideOver";
import { convertHtml } from "@/lib/parser";
import classNames from "classnames";

export type Props = {
  title: string;
  description?: string;
  href?: string;
  action?: string;
  mode?: WidgetMode;
  onClose?: (forever: boolean) => void;
  show?: boolean;
  template?: string;
  actionClass?: string;
};

const Slide: FunctionComponent<Props> = ({
  mode = "tray",
  show = false,
  onClose = null,
  template = "",
  title = "",
  description = "",
  href = "#",
  action = "",
  actionClass = "",
}) => {
  const [showSlide, setShowSlide] = useState(show);

  useEffect(() => {
    setShowSlide(show);
  }, [show]);
  if (template !== "") {
    const vnode = convertHtml(template);

    switch (mode) {
      case "tray":
        return <div>{vnode}</div>;
      case "display":
        return (
          <SlideOver
            show={showSlide}
            onClose={(forever) => {
              setShowSlide(false);
              if (onClose) {
                onClose(forever);
              }
            }}
            title={title}
          >
            {vnode}
          </SlideOver>
        );
    }
  } else {
    switch (mode) {
      case "tray":
        return null;
      case "display":
        return (
          <SlideOver
            show={showSlide}
            title={title}
            onClose={(forever) => {
              setShowSlide(false);
              if (onClose) {
                onClose(forever);
              }
            }}
          >
            <div class="max-w-xl text-sm text-gray-500">
              <p>{description}</p>
            </div>
            {href !== "" && action !== "" && (
              <div class="sm:flex sm:flex-shrink-0 sm:items-center sm:justify-end">
                <a
                  href={href}
                  target="_blank"
                  class={classNames(
                    "mt-2 inline-flex items-center rounded-md border border-transparent bg-brand-blue px-4 py-2 font-medium text-white shadow-sm  focus:outline-none focus:ring-0 text-sm",
                    actionClass
                  )}
                >
                  {action}
                </a>
              </div>
            )}
          </SlideOver>
        );
    }
  }
};

export default Slide;
