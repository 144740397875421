import { FunctionComponent, h } from "preact";
import { useEffect, useState } from "preact/hooks";

import Modal from "@/components/Modal";
import { convertHtml } from "@/lib/parser";
import type { WidgetMode } from "@/types";

export type Props = {
  title: string;
  description: string;
  href?: string;
  action?: string;
  template?: string;
  mode?: WidgetMode;
  onClose?: (forever: boolean) => void;
  show?: boolean;
};

const Prompt: FunctionComponent<Props> = ({
  title,
  description,
  href = "",
  mode = "tray",
  show = false,
  onClose = null,
  template = "",
  action = "",
}) => {
  if (template !== "") {
    const vnode = convertHtml(template);

    switch (mode) {
      case "tray":
        return <div>{vnode}</div>;
      case "display":
        if (show) {
          return (
            <Modal
              show={show}
              onClose={(forever) => {
                if (onClose) {
                  onClose(forever);
                }
              }}
              title={title}
            >
              {vnode}
            </Modal>
          );
        } else {
          return null;
        }
    }
  } else {
    switch (mode) {
      case "tray":
        return (
          <div class="">
            <h3 class="text-sm font-medium leading-4 text-gray-900">{title}</h3>
            <div class="mt-2">
              <div class="max-w-xl text-sm text-gray-500">
                <p>{description}</p>
              </div>
              {href !== "" && action !== "" && (
                <div class="sm:flex sm:flex-shrink-0 sm:items-center">
                  <a
                    href={href}
                    target="_blank"
                    class="mt-2 inline-flex items-center rounded-md border border-transparent bg-brand-blue px-4 py-2 font-medium text-white shadow-sm  focus:outline-none focus:ring-0 text-sm"
                  >
                    {action}
                  </a>
                </div>
              )}
            </div>
          </div>
        );
      case "display": {
        if (show) {
          return (
            <Modal
              show={show}
              onClose={(forever) => {
                if (onClose) {
                  onClose(forever);
                }
              }}
              title={title}
            >
              <div class="max-w-xl text-sm text-gray-500">
                <p>{description}</p>
              </div>
              {href !== "" && action !== "" && (
                <div class="sm:flex sm:flex-shrink-0 sm:items-center sm:justify-end">
                  <a
                    href={href}
                    target="_blank"
                    class="mt-2 inline-flex items-center rounded-md border border-transparent bg-brand-blue px-4 py-2 font-medium text-white shadow-sm  focus:outline-none focus:ring-0 text-sm"
                  >
                    {action}
                  </a>
                </div>
              )}
            </Modal>
          );
        } else {
          return null;
        }
      }
    }
  }
};

export default Prompt;
