import { FunctionComponent, h } from "preact";
import { useState } from "preact/hooks";

import type { WidgetMode } from "@/types";
import Modal from "@/components/Modal";

export type Props = {
  src: string;
  text?: string;
  mode?: WidgetMode;
  onClose?: (forever: boolean) => void;
  show?: boolean;
};

const Video: FunctionComponent<Props> = ({
  src,
  text = "",
  mode = "tray",
  show = false,
  onClose = null,
}) => {
  const [showModal, setShowModal] = useState(show);
  switch (mode) {
    case "tray":
      return (
        <div>
          <iframe
            className="w-full"
            height="120"
            src={src}
            title={text}
            allowFullScreen
          ></iframe>
        </div>
      );
    case "display": {
      if (show) {
        return (
          <Modal
            show={showModal}
            onClose={(forever) => {
              setShowModal(false);
              if (onClose) {
                onClose(forever);
              }
            }}
            title={text}
          >
            <div className="flex justify-center">
              <iframe
                className="w-full"
                height="240"
                src={src}
                title={text}
                allowFullScreen
              ></iframe>
            </div>
          </Modal>
        );
      } else {
        return null;
      }
    }
  }
};

export default Video;
